<template>
    <zw-sidebar @shown="shown"
                :title="$t('common.form.title.'+this.payload.title)"
    >
        <ValidationObserver tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col sm="12">
                        <zw-model-selector-group v-model="form.customer_id"
                                                 name="pre_todo.customer_id"
                                                 model-type="customer"
                                                 validate="required"
                                                 :callback="customerSelected"
                        />
                    </b-col>

                    <b-col cols="12">
                        <zw-model-selector-group v-model="form.offering_id"
                                                 name="pre_todo.offering_id"
                                                 model-type="offering"
                                                 :callback="offeringSelected"
                        />
                    </b-col>
                    <b-col cols="12">
                        <zw-model-selector-group v-model="form.contract_id"
                                                 name="pre_todo.contract_id"
                                                 model-type="customer-contract"
                                                 :callback="contractSelected"
                        />
                        <search-field v-model="form.contract_id"
                                      name="pre_todo.contract_id"
                                      :url="contractSearchUrl"
                                      :callback="contractSelected"

                                      ref="todos_contract_id"
                        ></search-field>
                    </b-col>
                </b-row>
                <b-row align-h="end">
                    <b-col sm="12" class="text-sm-right text-xs-center">
                        <b-button block @click="onSubmit" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </ValidationObserver>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import ValidateMixin from './../../mixins/validate'
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";

export default {
    name: 'project-form',
    components: {ZwModelSelectorGroup},
    mixins: [ValidateMixin],
    data() {
        return {
            loading: true,
            contractSearchUrl: window.apiUrl + '/customer-contract-search',
            payload: {},
            form: {
                customer_id: null,
                offering_id: null,
                contract_id: null,
            }
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getOfferingPeriods', 'getCustomerForms']),
        ...mapGetters('Customer', ['getCustomer']),
        shown() {
            this.loading = false
            this.payload.title = 'newTodo'
        },
        customerSelected(customer) {
            this.form.offering_id = null;

            this.form.contract_id = null;
            this.$refs.todos_contract_id.search = null;
        },
        offeringSelected(offering) {
            this.form.customer_id = offering.customer_id;
            this.form.contract_id = null;
            this.$refs.todos_contract_id.search = null;
        },
        contractSelected(contract) {
            this.form.customer_id = contract.customer_id;
            this.form.offering_id = null;
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$root.$children[0].openModal('todo-modal', {
                            kva_id: this.form.offering_id,
                            customer_id: this.form.customer_id,
                            contract_id: this.form.contract_id
                        },
                        this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
                    )
                }
            })
        },
    },
}
</script>